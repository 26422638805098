import React, { useEffect } from "react";

import { Downgraded, useState as useHookState } from "@hookstate/core";

import Layout from "../../../Components/Layout";
import AddVisitor from "../../../Components/AddVisitor";
import AppState from "../../../States/AppState";
import AreaRoles from "../../../States/AreaRoles";
import { navigate } from "gatsby";
import { RoleLogin } from "../../../utils/Auth";

const AddVisitorPage = ({ areaid, gateid }) => {
  const user = useHookState(AppState).attach(Downgraded).user.get();
  const areaRoleState = useHookState(AreaRoles).get();

  useEffect(() => {
    const currentArea = areaRoleState.areas.filter(
      ({ areaId: id }) => id.split("/")[2] === areaid
    )[0];

    if (currentArea) {
      if (
        currentArea.role.includes("ROLE_PARTNER") &&
        currentArea.role.length === 1
      ) {
        return;
      }
      if (currentArea.role.includes("ROLE_GATEKEEPER")) {
        return;
      } else if (user) {
        navigate(RoleLogin(user));
      }
    }
  }, [user, areaRoleState]);
  return (
    <Layout isPrivate showHeader>
      <AddVisitor
        areaId={areaid}
        gateId={gateid !== "_" ? gateid : undefined}
      />
    </Layout>
  );
};

export default AddVisitorPage;
