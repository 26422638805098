import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { navigate } from "gatsby";
import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import client from "../../gatsby-plugin-apollo/client";

import BackButton from "../../images/back_arrow_icon@2x.png";
import DefaultImg from "../../images/profile-pic-icon.png";

import {
  CreateVisitor,
  CreateVisitorHealth,
  Gate,
  GetGates,
  Tower,
} from "../../Interfaces/ParkingArea";
import { Manager, VisitorHealth } from "../../Interfaces/User";
import {
  CreateVisitorHealthQuery,
  CreateVisitorQuery,
  GetCustomTowerQuery,
} from "../../Query/ParkingArea";

import { ImgToBase64 } from "../../utils/Base64Img";
import Logger from "../../utils/Logger";
import Server from "../../utils/Server";
import { UploadMedia } from "../../utils/UploadImg";

import Loader from "../Loader";

import * as AddVisitorStyle from "./AddVisitor.module.scss";

interface Props {
  areaId: string;
  gateId?: string;
}

interface IVisitingPurpose {
  visitingPurposes: {
    edges: {
      node: {
        id: string;
        code: string;
        name: string;
      };
    }[];
  };
}

interface Inputs {
  purpose: string;
  visitorContactNumber: string;
  visitorName: string;
  residence?: string;
  covid: string;
  temperature: string;
  mask: string;
  gate: string;
}

interface IGetCustomTowers {
  custom_collection_queryResidences: {
    collection: Tower[];
  };
}

interface IGetResidence {
  residences: {
    collection: Tower[];
  };
}

interface IGetHealthAtt {
  healthMeasuringAttributes: {
    edges: {
      node: {
        id: string;
        name: string;
        code: "TEMP" | "MASK" | "VACCINATEd";
      };
    }[];
  };
}

interface GetManagers {
  parkingAreaManagers: {
    collection: Manager[];
  };
}

const GetResidencesQuery = gql`
  query GetResidences($parkingArea: String, $towerName: String) {
    residences(parkingArea: $parkingArea, towerName: $towerName) {
      collection {
        id
        flatName
        towerName
      }
    }
  }
`;

const GetPurpose = gql`
  query Purpose {
    visitingPurposes {
      edges {
        node {
          id
          code
          name
        }
      }
    }
  }
`;

const GetHealthAttri = gql`
  query getHealthAtt {
    healthMeasuringAttributes {
      edges {
        node {
          id
          name
          code
        }
      }
    }
  }
`;

const GetManagersQuery = gql`
  query GetManagers(
    $parkingArea: String
    $contactNumber: String
    $role: String
  ) {
    parkingAreaManagers(
      parkingArea: $parkingArea
      manager_contactNumber: $contactNumber
      role: $role
    ) {
      collection {
        id
        manager {
          id
          name
          contactNumber
          userMedias {
            collection {
              id
              mediaUrl
            }
          }
          userVehicles(status_code: "ACTIVE") {
            collection {
              id
              regNo
              isDefault
            }
          }
        }
      }
    }
  }
`;

const Index = ({ areaId, gateId }: Props) => {
  const { handleSubmit, register, setValue } = useForm();

  const [isDomesticHelp, setIsDomesticHelp] = useState(false);
  const [image, setImage] = useState<{ file?: File; url?: string }>();
  const [isLoading, setIsLoading] = useState(false);

  const {
    data: visitingPurpose,
    error: purposeError,
    loading: purposeLoading,
  } = useQuery<IVisitingPurpose>(GetPurpose);

  const { data: healthAtt } = useQuery<IGetHealthAtt>(GetHealthAttri);

  const {
    data: towers,
    error: towerError,
    loading: towerLoading,
  } = useQuery<IGetCustomTowers>(GetCustomTowerQuery, {
    variables: {
      itemsPerPage: 200,
      parkingArea: `/parking_areas/${areaId}`,
    },
  });

  const [
    getResidance,
    { data: residanceData, loading: residanceLoading, error: residanceError },
  ] = useLazyQuery<IGetResidence>(GetResidencesQuery);

  const [
    createVisitor,
    { error: createVisitorError, loading: createVisitorLoading },
  ] = useMutation<CreateVisitor>(CreateVisitorQuery);

  const [
    createVisitorHealth,
    { error: visitorHealthError, loading: visitorHealthLoading },
  ] = useMutation<CreateVisitorHealth>(CreateVisitorHealthQuery);

  useEffect(() => {
    const err =
      purposeError ||
      towerError ||
      residanceError ||
      createVisitorError ||
      visitorHealthError;
    if (err) Logger.showError(err);
  }, [
    residanceError,
    towerError,
    purposeError,
    createVisitorError,
    visitorHealthError,
  ]);

  const onHandleSubmit: SubmitHandler<Inputs> = (data) => {
    createVisitor({
      variables: {
        ...data,
        gate: gateId ? `gates/${gateId}` : data.gate,
        parkingArea: `/parking_areas/${areaId}`,
        residence:
          data.purpose === "/visiting_purposes/4" ? undefined : data.residence,
      },
    })
      .then((res) => {
        const visitor = res.data?.createResidenceVistor.residenceVistor;
        const temp = [
          { label: "TEMP", value: data.temperature },
          { label: "MASK", value: data.mask },
          { label: "VACCINATED", value: data.covid },
        ]
          .filter(({ value }) => value.length > 1)
          .map(({ label, value }): Promise<VisitorHealth> => {
            return new Promise((resolve, reject) => {
              createVisitorHealth({
                variables: {
                  visitor: visitor?.id,
                  attType: healthAtt?.healthMeasuringAttributes.edges.filter(
                    ({ node: { code } }) => code === label
                  )[0]?.node?.id,
                  attValue: value,
                },
              })
                .then((res) => {
                  if (
                    res.data?.createResidenceVisitorHealth
                      .residenceVisitorHealth
                  )
                    resolve(
                      res.data?.createResidenceVisitorHealth
                        .residenceVisitorHealth
                    );
                  else {
                    reject(new Error("No Data"));
                  }
                })
                .catch((err) => {
                  reject(err);
                });
            });
          });

        Promise.all(temp)
          .then(() => {
            if (image?.file) {
              setIsLoading(true);
              UploadMedia({
                name: image?.file?.name,
                path: image?.file,
                type: image?.file?.type,
              })
                .then(({ key }) => {
                  setIsLoading(true);
                  Server.get(
                    `/residence/visitor/${visitor?.id.split("/")[2]}/media`,
                    {
                      keyname: key,
                      mediaType: "GENERAL",
                    }
                  )
                    .then((res) => {
                      navigate(-1);
                      console.log(res);
                    })
                    .catch((err) => {
                      Logger.showError(err);
                    })
                    .finally(() => setIsLoading(false));
                })
                .catch((error) => {
                  Logger.showError(error);
                })
                .finally(() => setIsLoading(false));
            } else {
              navigate(-1);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const searchUser = (contactNumber: string) => {
    if (contactNumber.length === 10) {
      client
        .query<GetManagers>({
          query: GetManagersQuery,
          variables: {
            parkingArea: `/parking_aras/${areaId}`,
            contactNumber: contactNumber,
            role: "ROLE_DOMESTIC",
          },
        })
        .then((res) => {
          const result = res.data.parkingAreaManagers.collection[0];

          if (result) {
            const vehicle =
              res.data.parkingAreaManagers.collection[0]?.manager.userVehicles
                ?.collection;
            const media =
              res.data.parkingAreaManagers.collection[0]?.manager.userMedias
                ?.collection;

            if (media?.length) {
              setImage({ url: media[0]?.mediaUrl?.url });
            }
            if (vehicle?.length) {
              setValue("vehicleNumber", vehicle[0]?.regNo);
            }
            setValue("visitorName", result.manager.name);

            const pur = visitingPurpose?.visitingPurposes.edges.filter(
              ({ node: { code } }) => code === "HELP"
            )[0]?.node.id;

            setIsDomesticHelp(true);
            setValue("purpose", pur);
          } else {
            setValue("visitorName", undefined);
            setValue("purpose", undefined);
            setValue("vehicleNumber", undefined);
            setImage(undefined);
          }
        })
        .catch((err) => {
          Logger.showError(err);
        });
    } else {
      setValue("visitorName", undefined);
      setValue("purpose", undefined);
      setValue("vehicleNumber", undefined);
      setImage(undefined);
    }
  };

  return (
    <>
      <div className={AddVisitorStyle.container}>
        <div className={AddVisitorStyle.header}>
          <button onClick={() => navigate(-1)}>
            <img src={BackButton} alt="back" width={32} height={32} />
          </button>
        </div>
        <div className={AddVisitorStyle.content}>
          <div className={`${AddVisitorStyle.content__header} grediant1`}>
            <label htmlFor="img">
              <img
                src={image?.url || DefaultImg}
                alt="visitor"
                width={90}
                height={90}
                {...{ style: { borderRadius: "100px", objectFit: "cover" } }}
              />
              <span>Add</span>
            </label>
            <input
              id="img"
              type="file"
              hidden
              onChange={(e) => {
                const files = e.target.files;
                if (files) {
                  ImgToBase64(files[0])
                    .then((imgUrl) => {
                      setImage({ file: files[0], url: imgUrl });
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }
              }}
            />
          </div>
          <form
            className={AddVisitorStyle.form}
            onSubmit={handleSubmit(onHandleSubmit)}
          >
            <div className={AddVisitorStyle.form__group}>
              <label htmlFor="purpose">Contact Number</label>

              <input
                type="number"
                placeholder="Enter Visitor Number"
                {...register("visitorContactNumber")}
                required
                onChange={(e) => {
                  searchUser(e.target.value);
                }}
              />
            </div>
            <div className={AddVisitorStyle.form__group}>
              <label htmlFor="purpose">Purpose to Visit</label>
              <select
                className={AddVisitorStyle.form__group__select}
                id="purpose"
                {...register("purpose")}
                required
                onChange={(e) => {
                  setIsDomesticHelp(e.target.value === "/visiting_purposes/4");
                }}
              >
                <option value="">Select One</option>
                {visitingPurpose?.visitingPurposes.edges.map(
                  ({ node: { id, name } }) => (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  )
                )}
              </select>
            </div>
            <div className={AddVisitorStyle.form__group}>
              <label htmlFor="purpose">Name</label>

              <input
                type="text"
                placeholder="Enter Visitor Name"
                {...register("visitorName")}
                required
              />
            </div>
            <div className={AddVisitorStyle.form__group}>
              <label htmlFor="purpose">Vehicle Number</label>

              <input
                type="text"
                placeholder="Enter Visitor Name"
                {...register("vehicleNumber")}
              />
            </div>

            {!isDomesticHelp && (
              <>
                <div className={AddVisitorStyle.form__group}>
                  <label htmlFor="purpose">Tower Name</label>
                  <select
                    onChange={(e) => {
                      getResidance({
                        variables: {
                          parkingArea: `/parking_areas/${areaId}`,
                          towerName: e.target.value,
                        },
                      }).catch((err) => {
                        console.log(err);
                      });
                    }}
                    className={AddVisitorStyle.form__group__select}
                  >
                    <option value="">Select One</option>
                    {towers?.custom_collection_queryResidences.collection.map(
                      ({ id, towerName }) => (
                        <option key={`${id}/${towerName}`} value={towerName}>
                          {towerName}
                        </option>
                      )
                    )}
                  </select>
                </div>
                <div className={AddVisitorStyle.form__group}>
                  <label htmlFor="purpose">Flat Name</label>
                  <select
                    {...register("residence")}
                    className={AddVisitorStyle.form__group__select}
                    required={!isDomesticHelp}
                  >
                    <option value="">Select One</option>
                    {residanceData?.residences.collection.map(
                      ({ id, flatName }) => (
                        <option value={id} key={`${id}/${flatName}`}>
                          {flatName}
                        </option>
                      )
                    )}
                  </select>
                </div>
              </>
            )}

            <div className={AddVisitorStyle.form__group}>
              <label htmlFor="purpose">Covid Dose</label>
              <select
                className={AddVisitorStyle.form__group__select}
                {...register("covid")}
              >
                <option value="">Select One</option>
                {["DOSE1", "DOSE2", "NONE"].map((dose) => (
                  <option key={dose} value={dose}>
                    {dose}
                  </option>
                ))}
              </select>
            </div>
            <div className={AddVisitorStyle.form__group}>
              <label htmlFor="purpose">
                Body Temperature( <sup>o</sup>C)
              </label>
              <input
                type="text"
                placeholder="Enter Body Temperature In Celcius"
                {...register("temperature")}
              />
            </div>
            <div className={AddVisitorStyle.form__group}>
              <label htmlFor="purpose">Is Wearing Mask</label>
              <select
                className={AddVisitorStyle.form__group__select}
                {...register("mask")}
              >
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
            <div className={AddVisitorStyle.submit}>
              <button type="submit">Submit</button>
            </div>
          </form>
        </div>
      </div>
      <Loader
        isLoading={
          purposeLoading ||
          towerLoading ||
          residanceLoading ||
          createVisitorLoading ||
          visitorHealthLoading ||
          isLoading ||
          false
        }
      />
    </>
  );
};

export default Index;
