import axios from "axios";
import { Media } from "../Interfaces/Media";
import Server from "../utils/Server";

export interface FileMedia {
  id?: number;
  key?: string;
  name: string;
  type: string | MediaType;
  path: string | File;
  thumbPath?: string | File;
}

enum MediaType {
  logo = "LOGO",
  cover = "COVER",
  image = "IMAGE",
  document = "DOCUMENT",
  canceledCheque = "CANCELCHEQUE",
  gstCerificate = "GSTCERTIFICATE",
  pancard = "PANCARD",
  paytmQrCode = "PAYTMQRCODE",
  video = "VIDEO",
}

const uploadImage = (
  file: File,
  type: "USER" | "PARKING" | "VISITOR" | "COMPLAINT",
  id: string
): Promise<Media> => {
  return new Promise((resolve, reject) => {
    Server.get(
      `/s3SignedUrl?filename=${
        (type === "USER" && "user") ||
        (type === "PARKING" && "parking") ||
        (type === "VISITOR" && "visitor")
      }&mime=${encodeURIComponent(file.type)}`
    )
      .then((response: any) => {
        const formData = new FormData();
        formData.append("file", file);
        Server.put(response?.url, formData)
          .then((res) => {
            const shortId = id?.split("/")[2];
            Server.get(
              `/${
                (type === "USER" && "user") ||
                (type === "PARKING" && "parking") ||
                (type === "VISITOR" && "residence/visitor") ||
                (type === "COMPLAINT" && "residence/complaint")
              }/${shortId}/media?keyname=${encodeURIComponent(
                response?.key
              )}&mediaType=PHOTO`
            )
              .then((r: any) => {
                resolve(r);
              })
              .catch((error) => {
                reject(error);
              });
          })
          .catch((err) => {
            reject(err);
          });
      })
      .catch((e) => {
        reject(e);
      });
  });
};

const deleteImg = (mediaId: string, mediaType: "usermedia") => {
  return new Promise((resolve, reject) => {
    Server.del(`/${mediaType}/${parseInt(mediaId)}`)
      .then(() => {
        resolve(true);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const UploadMedia = (media: FileMedia): Promise<FileMedia> => {
  return new Promise((resolveUpload, rejectUpload) => {
    if (typeof media.path !== "string") {
      const mimeType = media.path.type;
      let key = "";
      Server.get(`/s3SignedUrl?filename=${media.name}&mime=${mimeType}`)
        .then((response: any) => {
          const signedUrl = response.url;
          key = response.key;
          const options = {
            headers: {
              "Content-Type": mimeType,
            },
          };
          axios
            .put(signedUrl, media.path, options)
            .then(() => {
              resolveUpload({ ...media, key });
            })
            .catch((e) => {
              rejectUpload(e);
            });
        })
        .catch((e) => {
          rejectUpload(e);
        });
    } else {
      resolveUpload({ ...media });
    }
  });
};

export { uploadImage, deleteImg, UploadMedia };
