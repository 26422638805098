// extracted by mini-css-extract-plugin
export var container = "AddVisitor-module--container--xzn0N";
export var header = "AddVisitor-module--header--IhahS";
export var content = "AddVisitor-module--content--rsj6s";
export var content__header = "AddVisitor-module--content__header--77Aip";
export var form = "AddVisitor-module--form--2awJj";
export var form__checkbox = "AddVisitor-module--form__checkbox--0-XF2";
export var form__group = "AddVisitor-module--form__group--EcTHi";
export var form__group__files = "AddVisitor-module--form__group__files--8EX82";
export var form__group__input = "AddVisitor-module--form__group__input--prK+2";
export var form__group__select = "AddVisitor-module--form__group__select--GNzRT";
export var textarea = "AddVisitor-module--textarea--AhcLE";
export var submit = "AddVisitor-module--submit--jjoZR";